/* Start custom */

/* -- this style fix for Table scroll x issue: https://github.com/ant-design/ant-design/issues/26621 */
.ant-table {
  .ant-table-container {
    overflow-x: auto;
    table {
      min-width: 800px;
    }
  }
}
/* End custom */

.border-table-search-input {
  width: 250px;
}

.border-table-search-input .ant-input-group-addon {
  display: none;
}

.ant-space-item:last-child {
  /* margin-left: auto; */
}

.search-bord-rad {
  border-radius: 6px !important;
}